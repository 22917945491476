import { createMedia } from '@artsy/fresnel';

const ExampleAppMedia = createMedia({
  breakpoints: {
    xs: 0,
    s: 576,
    sm: 768,
    md: 1024,
    lg: 1200
  },
  interactions: {
    landscape: '(orientation: landscape)',
    portrait: '(orientation: portrait)'
  }
});

// Make styles for injection into the header of the page
export const mediaStyles = ExampleAppMedia.createMediaStyle();

export const { Media, MediaContextProvider } = ExampleAppMedia;
